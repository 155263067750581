<template>
    <div class="vidocent">
        <div style="display: flex;">
          <Headbuttom></Headbuttom>
           <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />播放
            </el-button>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input></div>
      </div>
      <div class="attheadfrom" v-show="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        <el-form-item label="设备序列号">
          <el-input
            v-model="searchParams.serial_number"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="设置安装位置">
          <el-input
            v-model="searchParams.name"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
      </div>


      <el-dialog
        title="查看在线设备"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose">

         <el-table
            :border="true"
            :data="tableData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            >
            <el-table-column
              type="selection"
              width="40">
            </el-table-column>

            <el-table-column
              prop="name"
              label="设备安装位置"
              >
            </el-table-column>
            
            <el-table-column
              prop="serial_number"
              label="设备序列号">
            </el-table-column>
          </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="handModl">播放</el-button>
          <el-button size="small" @click="dialogVisible = false">关闭</el-button>
          
        </span>
      </el-dialog>


        <div class="viocent" v-show="shows">
            <div class="vido" v-for="item in Tabvideolist" :key="item.id">
              <video
                  class="video-js"
                  controls
                  preload="auto"
                  poster="//vjs.zencdn.net/v/oceans.png"
                  data-setup='{}'>
                <source src="rtmp://media3.scctv.net/live/scctv_800"  type="rtmp/mp4" />
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a
                  web browser that
                  <a href="https://videojs.com/html5-video-support/" target="_blank">
                    supports HTML5 video
                  </a>
                </p>
              </video>
              <p style="line-height: 30px;">{{item.name}}</p>
            </div>
            
        </div>
       
    </div>
</template>
<script src="https://vjs.zencdn.net/7.15.4/video.min.js"></script>
<script>

export default {
    data(){
        return{
            shows:false,
            dialogVisible: false,
            flag:false,
            searchParams:{
                serial_number:'',
                name:""
            },
            nowPlayVideoUrl: 'rtmp://media3.scctv.net/live/scctv_800',
            tableData:[],
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            serial_number:'',
            Tabvideolist:[],
            s:[]
        }
    },
    mounted() {
        this.initVideoPlayer()
    },
    methods:{

        onPlus(){
          this.dialogVisible = !this.dialogVisible
          this.handleList()
        },
        handleClose(){
          this.dialogVisible = false
        },
        handModl(){
          this.shows = true
          this.Tabvideolist = this.s
          this.dialogVisible = false
          this.$forceUpdate() 
          console.log(`099`,this.Tabvideolist)
        },
        // 表格全选
        handleSelectionChange(val){
          this.s = val
          this.$forceUpdate() 
        },
        handleList(){
          let data={
            method:'co.ipc.online.device.list',
            manager_id:this.UserInfo.manager_id,
            agent_id:this.UserInfo.agent_id,
            dept_id:'0',
            serial_number:this.serial_number,
          }
          this.$serve(data).then(res=>{
            if(res.data.code==0){
              this.tableData = res.data.data
            }
            console.log(res)
          })
        },

        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){

        },
        initVideoPlayer() {
            // 第一个选中的要播放的video标签, 记得是video标签, 
            const currentInstance = this.$video(document.querySelector('#video'), {
                autoplay: true, // 是否自动播放
                controls: true, // 是否显示控件
            })

            currentInstance.src({
                src: this.nowPlayVideoUrl,
                type: 'rtmp/flv', // 这个type值必写, 告诉videojs这是一个rtmp流视频
            })
        }
    

    }
}
</script>
<style scoped>
.vidocent{
    background-color: #fff;
    padding: 20px;
    min-height: 500px;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
.viocent{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    flex-direction: row;
    
}
.viocent::after{
  content: "";
  width: 250px;
  height: 250px;
}
.vido{
    width: 250px;
    height: 250px;
    border: 1px solid #cccccc;
    margin-bottom: 50px;
}

.video-js {
        width: 250px;
        height: 250px
    }

</style>